import atricureWork from './AtricureWork.webp'
import blueprintsWork from './BlueprintsWork.webp'
import medvisionWork from './medvisionscreencap.webp'
import mayoWork from './mayowork.jpg'
import compassWork from './compasswork.jpg'
import ribbonWork from './ribbonwork.png'

class WorkBlock {
    constructor(projectTitle, company, brief, tech, impact, image, workTag, langTags, link, path) {
        this.projectTitle = projectTitle;
        this.company = company;
        this.brief = brief;
        this.tech = tech;
        this.impact = impact;
        this.image = image;
        this.workTag = workTag;
        this.langTags = langTags;
        this.link = link;
        this.path = path;
    }
}

var compasshealthapp = new WorkBlock(
    "Campus Resource Navigator",
    "Compass Health",
    "After struggling to find on-campus healthcare resources at Michigan, I created a centralized platform that allows students to search and filter through over 100+ resources. Building this involved creating a data ingestion pipeline to automatically collect data on 100+ Michigan healthcare resources, conducting 30+ user-testing sessions with community members, and finally designing and building the platform. Compass is the 1st centralized database of healthcare resources at Michigan.",
    "Data ingestion pipeline utilized Python and BeautifulSoup run on Databricks. Web platform utilizes React frontend, Django backend, AWS hosting, and Algolia search service.",
    "$18,000 in University funding, >2500 users in first 90 days of launch, Average Net Promoter Score of 8.5/10",
    compassWork,
    { name: "Fullstack Eng", color: "#FF9A00" },
    [{ name: "React", color: "#0094FF" }, { name: "Django", color: "#C9CD00" }],
    "https://www.compasshealth.app/about",
    '/work/compasshealthapp',
);

var ribbonhealthapp = new WorkBlock(
    "API Data Pipeline",
    "Ribbon Health",
    "Architected and engineered an automated ELT data pipeline to clean and organize historical API logs.",
    "API logs fetched from multiple SQL tables. Cleaning and transformation of data performed in Databricks with Python and Pyspark. Data visualized with Looker.",
    "Immediate insights for Product & Customer teams from ~40M historical API logs.",
    ribbonWork,
    { name: "Backend Eng", color: "#FF9A00" },
    [{ name: "Python", color: "#C9CD00" }, { name: "SQL", color: "#019684" }],
    "",
    '/work/ribbonhealthapp',
);

var mayoclinicapp = new WorkBlock(
    "Lab Operations Platform",
    "Mayo Clinic",
    "Working on a $XXM project for Mayo's highest throughput laboratory, I built a Fullstack interface allowing clinicians to easily view all patient blood samples present in the lab. This new platform unified 5 different, outdated web systems the lab had been utilizing prior.",
    "Angular frontent used to display blood sample data. Spring (Java) backend connected to a SQL database utilized to fetch lab data. Application deployed within Microsoft DevOps CI/CD pipeline.",
    "Centralized and intuitive platform that allows clinicians to process blood samples more quickly and effectively.",
    mayoWork,
    { name: "Fullstack Eng", color: "#FF9A00" },
    [{ name: "Angular", color: "#B00B00" }, { name: "Spring (Java)", color: "#9800B0" }, { name: "SQL", color: "#019684"}],
    "",
    '/work/mayoclinicapp',
);

var medvisionweb = new WorkBlock(
    "Client-Facing Website",
    "MedVision",
    "I developed a comprehensive website with an account management system to act as the face of the organizaiton and aid onboarding of new clients",
    "Website has a React frontend and Node.js backend with account management system, deployed through AWS",
    "Visually stunning portfolio of organization's mission and purpose, along with scalable account sign-up infrastructure",
    medvisionWork,
    {name: "Fullstack Eng", color: "#FF9A00"},
    [{name: "React", color: "#0094FF"}, {name: "Node.js", color: "#19AF00"}],
    "https://www.medvision.ai/",
    '/work/medvisionweb',
);

var inventoryapp = new WorkBlock(
    "Donation Inventory App",
    "Blueprints For Pangaea",
    "I acted as product manager for the development of this mobile web-app and desktop admin console combination, meant to scan product labels to help better inventory donated medical supplies.",
    "Mobile web-app uses Tesseract OCR to recognize text in image of scanned label. Desktop admin console uses Django backend connected to SQLite database. Both parts of app use React.js frontend and are hosted on Microsoft Azure.",
    "$20,000 in University funding, 150% increase in inventorying efficiency.",
    blueprintsWork,
    {name: "Product Management", color: "#CC8899"},
    [{name: "React", color: "#0094FF"}, {name: "Django", color: "#C9CD00"}],
    "https://github.com/gveldhuis/BlueprintsInventoryApp",
    '/work/inventoryapp',
);

var atricureapp = new WorkBlock(
    "Factory Staffing App",
    "AtriCure",
    "I developed an interactive, internal web-app to help manufacturing supervisors calculate optimal staffing for production lines, based on current manpower, historical output, and consumer demand.",
    "Utilized React.js fronted with a Node.js backend, connected to mySQL database containing past production data.",
    "85% utilization among supervisors after only 2 weeks.",
    atricureWork,
    {name: "Fullstack Eng", color: "#FF9A00"},
    [{name: "React", color: "#0094FF"}, {name: "Node.js", color: "#19AF00"}],
    "",
    '/work/atricureapp',
);

export let workProjects = [compasshealthapp, ribbonhealthapp, mayoclinicapp, medvisionweb, inventoryapp, atricureapp];